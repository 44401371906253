<template>
  <div class="pa-5">
    <div class="d-flex mb-5">
      <h3>Shipments</h3>
      <v-spacer />
      <v-btn color="green" depressed dark @click="openNewShipmentDialog">
        + create shipment
      </v-btn>
    </div>

    <div class="d-flex align-center mb-3">
      <v-text-field
        v-model="filters.q"
        placeholder="Customer, invoice number, tracking number, EAN..."
        solo
        flat
        outlined
        dense
        hide-details
        prepend-inner-icon="mdi-magnify"
        class="mr-3"
        @change="getOrders()"
        @click:clear="
          filters.q = '';
          getOrders();
        "
        clearable
      />
      <v-select
        v-model="filters.status"
        outlined
        class="white mr-3"
        hide-details
        dense
        label="Shipment status"
        :items="[
          {
            text: 'All',
            value: 'all'
          },
          {
            text: 'Created',
            value: 'created'
          },
          {
            text: 'Shipped',
            value: 'shipped'
          }
        ]"
        @change="getOrders()"
      /><v-select
        v-model="filters.invoiceStatus"
        outlined
        class="white mr-3"
        hide-details
        dense
        label="Invoice status"
        :items="[{ value: 'all', text: 'All' }, ...waveInvoiceStatuses()]"
        @change="getOrders()"
      />
      <v-menu
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="filters.dates.length > 0 ? filters.dates.join(' ~ ') : ''"
            prepend-inner-icon="mdi-calendar"
            outlined
            hide-details
            dense
            class="white"
            placeholder="Select date"
            clearable
            @click:clear="
              filters.dates = [];
              getOrders();
            "
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filters.dates"
          range
          no-title
          scrollable
          @input="
            filters.dates.sort((a, b) => (a > b ? 1 : -1));
            getOrders();
          "
        />
      </v-menu>
    </div>

    <v-card>
      <v-simple-table>
        <thead>
          <tr class="blue-grey lighten-3">
            <th>ID</th>
            <th>Date</th>
            <th>Customer</th>
            <th>Name</th>
            <th>EAN</th>
            <th>Invoice</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(order, orderK) in orders"
            :key="order.id"
            :class="{
              'blue-grey lighten-5': order.status !== 'shipped' && orderK % 2,
              'green lighten-4': order.status === 'shipped'
            }"
          >
            <td>#{{ order.id }}</td>
            <td>{{ moment(order.createdAt).format("ll") }}</td>
            <td>{{ order.customerName }}</td>
            <td>
              <div
                v-for="item in order.items"
                :key="item.id"
                class="text-truncate"
                style="max-width: 600px"
              >
                <v-badge inline :content="(item.quantity || '0') + 'x'" />
                {{ item.name }}
              </div>
            </td>
            <td>
              <div v-for="item in order.items" :key="item.id">
                {{ item.ean }}
              </div>
            </td>
            <td>
              <div v-if="order.invoice">
                <v-chip
                  :color="
                    waveInvoiceStatuses().filter(
                      x => x.value === order.invoice.status
                    )[0].color
                  "
                  :href="order.invoice.viewUrl"
                  target="_blank"
                >
                  <v-icon>mdi-pdf-box</v-icon>
                  {{ order.invoice.invoiceNumber }}:
                  {{
                    waveInvoiceStatuses().filter(
                      x => x.value === order.invoice.status
                    )[0].text
                  }}
                </v-chip>
              </div>
            </td>
            <td>
              <v-switch
                v-model="order.status"
                true-value="shipped"
                false-value="created"
                hide-details
                dense
                color="green"
                @change="sendOrderField(order, 'status')"
                class="my-0"
              />
            </td>
            <td class="text-right">
              <span
                  v-for="shipment in order.shipments"
                  :key="shipment.id"
              >
              <a
                  :title="shipment.speditor + ' ' + shipment.number"
                  :href="upsTrackingLink(shipment.number)"
                  target="_blank"
              ><v-icon small class="ma-1" color="black">mdi-truck</v-icon></a
              >
              <a
                  :href="
                  'https://w.thorbroadcast.com/api/ups/label/' +
                    shipment.number +
                    '.gif'
                "
                  target="_blank"
              >
                <v-icon small class="ma-1" color="black">mdi-file-gif-box</v-icon>
              </a
              >
              </span>
              <v-btn icon @click="printWZ(order)"
                ><v-icon small>mdi-printer</v-icon></v-btn
              >
              <v-btn icon color="blue" @click="showOrder(order)">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-pagination
        v-model="filters.page"
        :length="pages"
        :total-visible="5"
        @change="getOrders({ doNotChangePage: true })"
      />
    </v-card>
    <v-dialog
      v-model="orderDialog.show"
      v-if="orderDialog.order"
      max-width="1200px"
    >
      <v-toolbar flat>
        <v-toolbar-title class="d-flex flex-grow-1 align-center">
          <span class="mr-5">Order #{{ orderDialog.order.id }}</span>
          <v-spacer />
          <v-switch
            v-model="orderDialog.order.status"
            true-value="shipped"
            false-value="created"
            label="Mark as complete"
            hide-details
            color="green"
            @change="sendOrderField(orderDialog.order, 'status')"
          />
        </v-toolbar-title>
        <v-btn icon @click="orderDialog.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card
        flat
        style="border-top-left-radius: 0; border-top-right-radius: 0"
        class="pa-5"
      >
        <v-row>
          <v-col cols="3">
            <v-card flat color="blue-grey lighten-5">
              <div class="pa-5">
                <div
                  class="text-center mb-5"
                  style="margin-top: -50px; position: relative"
                >
                  <v-btn color="purple" fab dark depressed x-large>
                    <v-icon>mdi-account-box</v-icon>
                  </v-btn>
                </div>
                <div>
                  <strong>{{ orderDialog.order.customerName }}</strong>
                </div>
                <div>
                  <v-icon>mdi-email-outline</v-icon>
                  {{ orderDialog.order.customerEmail }}
                </div>
                <div>
                  <v-icon>mdi-phone-outline</v-icon>
                  {{ orderDialog.order.customerPhone }}
                </div>
                <AddressRender :address="orderDialog.order.address" />
                <div class="d-flex">
                  <v-spacer />
                  <v-btn
                    depressed
                    outlined
                    small
                    rounded
                    @click="editCustomer.show = true"
                  >
                    Edit customer
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="9">
            <div v-if="orderDialog.order.shipments.length > 0" class="mb-3">
              <v-chip
                color="green lighten-2"
                close-label="Void label"
                class="mr-3"
                close
                close-icon="mdi-delete-outline"
                v-for="(shipment, shipmentK) in orderDialog.order.shipments"
                :key="shipmentK"
                :href="
                  'https://w.thorbroadcast.com/api/ups/label/' +
                    shipment.number +
                    '.gif'
                "
                target="_blank"
                @click:close="voidShipment(shipment, orderDialog.order)"
              >
                {{ shipment.speditor }} {{ shipment.number }}
              </v-chip>
            </div>
            <v-alert
              v-if="orderDialog.order.comment"
              color="red lighten-4 red--text"
              >{{ orderDialog.order.comment }}</v-alert
            >
            <v-card flat color="blue-grey lighten-5 pa-5">
              <ShipmentSize
                v-model="orderDialog.data.package.size"
              ></ShipmentSize>
            </v-card>
            <BillThirdParty v-model="orderDialog.order.billThirdParty" />
            <div class="font-weight-black mt-3 mb-1">
              Items:
              <v-btn @click="$set(orderDialog.order,'showEditPositions', true)" x-small color="blue" outlined>Edit invoice positions</v-btn>
              <InvoicePositions v-if="orderDialog.order.invoice" si v-model="orderDialog.order.showEditPositions" :invoice-id="orderDialog.order.invoice.id" />
            </div>
            <v-sheet outlined>
              <v-simple-table>
                <tbody>
                  <tr v-for="item in orderDialog.order.items" :key="item.id">
                    <td>
                      <v-img
                        v-if="item.product && item.product.thumbnail"
                        :src="item.product.thumbnail"
                        aspect-ratio="1"
                        contain
                        max-width="100px"
                      />
                    </td>
                    <td>
                      <div>{{ item.name }}</div>
                      <div class="text--secondary">{{ item.ean }}</div>
                    </td>
                    <td>
                      {{ item.quantity }}
                    </td>
                    <td>
                      <div v-if="item.product">
                        <v-chip
                          v-for="(shelf, shelfK) in item.product.shelfs"
                          :key="shelfK"
                          :style="{
                            backgroundColor:
                              shelf.active ||
                              (typeof shelf.active === 'undefined' &&
                                shelfK === 0)
                                ? '#dfffdf !important'
                                : 'white !important'
                          }"
                          @click="
                            item.product.shelfs.forEach(x =>
                              $set(x, 'active', false)
                            );
                            shelf.active = true;
                          "
                          outlined
                          color="blue"
                          class="mr-1"
                          small
                        >
                          <ShelfName
                            :shelf="allShelfs.find(x => x.id === shelf.id)"
                          />
                        </v-chip>
                      </div>
                    </td>
                    <td>
                      <v-btn
                        :href="item.link"
                        target="_blank"
                        icon
                        outlined
                        color="blue"
                        small
                        ><v-icon small>mdi-link-variant</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-sheet>
          </v-col>
        </v-row>
        <div class="d-flex mt-5">
          <v-spacer />
          <v-btn @click="createShipment" depressed rounded color="blue" dark
            >Create shipment</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newShipmentDialog.show" persistent max-width="1200px">
      <v-toolbar>
        <v-toolbar-title>New shipment</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="newShipmentDialog.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-sheet class="pa-5">
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              label="Select customer"
              v-model="selectCustomer.customer"
              :search-input.sync="selectCustomer.q"
              :items="selectCustomer.customers"
              :loading="selectCustomer.loading"
              return-object
              item-text="name"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle style="font-size: 0.8em">
                    <AddressRender :address="data.item.address" />
                  </v-list-item-subtitle>
                </v-list-item-content>
                <div class="text--disabled">
                  updated:
                  {{
                    data.item.updatedAt
                      ? moment(data.item.updatedAt).format("ll")
                      : "never"
                  }}
                </div>
              </template>
            </v-autocomplete>
            <div class="font-weight-black mb-3">Customer info</div>
            <v-text-field v-model="newShipmentDialog.data.name" label="Name" />
            <v-text-field v-model="newShipmentDialog.data.contactName" label="Contact name" />
            <v-text-field
              v-model="newShipmentDialog.data.phone"
              label="Phone"
            />
            <v-text-field
              v-model="newShipmentDialog.data.email"
              label="Email"
            />
            <CustomerAddress v-model="newShipmentDialog.data.address" />
            <BillThirdParty v-model="newShipmentDialog.data.billThirdParty" />
          </v-col>
          <v-col cols="6">
            <div class="font-weight-black mb-3">Package size</div>
            <ShipmentSize v-model="newShipmentDialog.data.size" />
            <div class="my-3">
              <v-btn @click="calculateCost" depressed color="blue" class="mr-2"
                >Calculate cost</v-btn
              >
              <v-btn
                @click="sendNewShipment"
                depressed
                color="green"
                :disabled="newShipmentDialog.choices.length === 0"
                >Send</v-btn
              >
            </div>
            <div v-if="newShipmentDialog.shipment">
              <a
                :href="
                  'https://w.thorbroadcast.com/api/ups/label/' +
                    newShipmentDialog.shipment.number +
                    '.gif'
                "
                target="_blank"
                >{{ newShipmentDialog.shipment.speditor }}
                {{ newShipmentDialog.shipment.number }}</a
              >
            </div>
            <div v-if="newShipmentDialog.choices.length > 0">
              <v-simple-table class="mt-5">
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Price</th>
                    <th>Negotiated price</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sc, sck) in newShipmentDialog.choices" :key="sck">
                    <td>{{ sc.name }}</td>
                    <td>{{ sc.price.toFixed(2) }}</td>
                    <td>{{ sc.negotiated.toFixed(2) }}</td>
                    <td>
                      <v-btn
                        small
                        depressed
                        @click="
                          newShipmentDialog.data.size.service = sc.service
                        "
                        >Select</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="editCustomer.show" persistent max-width="800px">
      <v-card v-if="orderDialog.order">
        <v-toolbar flat>
          <v-toolbar-title>Edit customer</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editCustomer.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-tabs>
            <v-tab>Basic</v-tab>
            <v-tab-item>
                <v-text-field
                        v-model="orderDialog.order.customerName"
                        label="Name"
                />
                <v-text-field
                        v-model="orderDialog.order.customerContactName"
                        label="Contact name"
                />
              <v-text-field
                v-model="orderDialog.order.customerEmail"
                label="Email"
              />
              <v-text-field
                v-model="orderDialog.order.customerPhone"
                label="Phone"
              />
            </v-tab-item>
            <v-tab>Address</v-tab>
            <v-tab-item>
              <CustomerAddress v-model="orderDialog.order.address" />
            </v-tab-item>
          </v-tabs>
          <v-btn @click="saveCustomer">Save</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import functions from "../mixins/functions";
import globals from "../mixins/globals";
import AddressRender from "../components/AddressRender";
import ShipmentSize from "../components/ShipmentSize";
import CustomerAddress from "../components/CustomerAddress";
import ShelfName from "../components/ShelfName";
import BillThirdParty from "../components/BillThirdParty";
import InvoicePositions from "@/components/InvoicePositions";

export default {
  name: "Orders",
  components: {
    InvoicePositions,
    BillThirdParty,
    ShelfName,
    CustomerAddress,
    ShipmentSize,
    AddressRender
  },
  mixins: [functions, globals],
  data() {
    return {
      orders: [],
      filters: {
        q: "",
        page: 1,
        status: "created",
        invoiceStatus: "all",
        dates: []
      },
      allShelfs: [],
      pages: 1,
      orderDialog: {
        show: false,
        order: null,
        data: {
          package: {
            size: null
          }
        }
      },
      newShipmentDialog: {
        show: false,
        shipment: null,
        choices: [],
        data: {
          size: null,
          name: "",
          contactName: "",
          phone: "",
          email: "",
          address: null,
          billThirdParty: null
        }
      },
      editCustomer: {
        show: false
      },
      selectCustomer: {
        loading: false,
        q: "",
        customers: []
      }
    };
  },
  created() {
    this.getOrders();
    axios.get(this.globals.apiUrl + "/shelf/list").then(res => {
      res.data.forEach(x => {
        this.allShelfs.push(x);
        x.children.forEach(y => this.allShelfs.push(y));
      });
    });
  },
  watch: {
    "selectCustomer.customer": {
      deep: true,
      handler(val) {
        if (val) {
          this.newShipmentDialog.data.name = val.name;
          this.newShipmentDialog.data.phone = val.phone;
          this.newShipmentDialog.data.email = val.email;
          const addr = val.shippingDetails
            ? val.shippingDetails.address
            : val.address;
          this.newShipmentDialog.data.address.addressLine1 = addr.addressLine1;
          this.newShipmentDialog.data.address.addressLine2 = addr.addressLine2;
          this.newShipmentDialog.data.address.city = addr.city;
          this.newShipmentDialog.data.address.postalCode = addr.postalCode;
          this.newShipmentDialog.data.address.country = addr.country;
          this.newShipmentDialog.data.address.province = addr.province;
        }
      }
    },
    "selectCustomer.q": {
      handler(val) {
        if (!val) return;

        this.selectCustomer.loading = true;
        axios
          .post(this.globals.apiUrl + "/customer/list", {
            q: val,
            page: 1
          })
          .then(res => {
            this.selectCustomer.customers = res.data.customers;
          })
          .catch(err => console.log(err))
          .finally(() => {
            this.selectCustomer.loading = false;
          });
      }
    }
  },
  methods: {
    calculateCost() {
      this.newShipmentDialog.choices = [];
      this.$store.commit("showLoading");
      axios
        .post(
          this.globals.apiUrl + "/ups/calculate",
          this.newShipmentDialog.data
        )
        .then(res => {
          this.newShipmentDialog.choices = res.data;
        })
        .catch(reason => {
          this.notyf(reason.response.data.message, "error");
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    printWZ(order) {
      window.open(this.globals.apiUrl + "/wz/" + order.id, "_blank");
    },
    sendOrderField(order, field) {
      axios
        .post(this.globals.apiUrl + "/orders/" + order.id + "/update", {
          [field]: order[field]
        })
        .catch(reason => {
          this.notyf(reason.response.data.message, "error");
        });
    },
    saveCustomer() {
      axios
        .post(
          this.globals.apiUrl +
            "/orders/" +
            this.orderDialog.order.id +
            "/editCustomer",
          this.orderDialog.order
        )
        .then(() => {
          this.editCustomer.show = false;
        })
        .catch(reason => {
          this.notyf(reason.response.data.message, "error");
        });
    },
    voidShipment(shipment, order) {
      if (confirm("Are you sure you want to void this label?")) {
        axios
          .post(this.globals.apiUrl + "/ups/void/" + shipment.number)
          .then(() => {
            order.shipments = order.shipments.filter(x => x.id !== shipment.id);
          })
          .catch(reason => {
            this.notyf(reason.response.data.message, "error");
          });
      }
    },
    sendNewShipment() {
      axios
        .post(this.globals.apiUrl + "/ups/create", {
          size: this.newShipmentDialog.data.size,
          billThirdParty: this.newShipmentDialog.data.billThirdParty,
          name: this.newShipmentDialog.data.name,
          phone: this.newShipmentDialog.data.phone,
          email: this.newShipmentDialog.data.email,
          address: this.newShipmentDialog.data.address
        })
        .then(res => {
          this.newShipmentDialog.shipment = res.data.shipment;
        })
        .catch(reason => {
          if (
            reason.response &&
            reason.response.data &&
            reason.response.data.message
          ) {
            this.notyf(reason.response.data.message, "error");
          } else {
            this.notyf("create error", "error");
          }
        });
    },
    openNewShipmentDialog() {
      this.newShipmentDialog.shipment = null;
      this.newShipmentDialog.show = true;
    },
    createShipment() {
      axios
        .post(this.globals.apiUrl + "/ups/create", {
          size: this.orderDialog.data.package.size,
          order: this.orderDialog.order
        })
        .then(res => {
          this.orderDialog.order.shipments.push(res.data.shipment);
          this.orders.forEach(x => {
            if (x.id === res.data.order.id) {
              if (x.status === "created") {
                x.status === "shipped";
              }
              x.shipments.push(res.data.shipment);
            }
          });
        })
        .catch(reason => {
          if (
            reason.response &&
            reason.response.data &&
            reason.response.data.message
          ) {
            this.notyf(reason.response.data.message, "error");
          } else {
            this.notyf("create error", "error");
          }
        });
    },
    showOrder(order) {
      this.orderDialog.order = order;
      if (order.shippingData && order.shippingData.service) {
        this.orderDialog.data.package.size = order.shippingData;
      }
      this.orderDialog.show = true;
    },
    getOrders(opt) {
      this.$store.commit("showLoading");
      if (!opt || !opt.doNotChangePage) {
        this.filters.page = 1;
      }

      axios
        .post(this.globals.apiUrl + "/orders/list", this.filters)
        .then(res => {
          this.orders = res.data.orders;
          this.pages = res.data.pages;
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    }
  }
};
</script>

<style scoped></style>
