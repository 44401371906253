<template>
  <div>
    <v-checkbox
      v-model="useThirdParty"
      label="Bill Third Party"
      @change="inp"
      hide-details
      dense
    />
    <v-sheet outlined class="pa-1" v-if="useThirdParty && billThirdParty">
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            v-model="billThirdParty.accountNumber"
            label="UPS account number"
            hide-details
            dense
            @change="inp"
          />
        </v-col>
        <v-col cols="6">
          <CustomerAddressAc
            v-model="billThirdParty.postalCode"
            field="zip"
            label="Postal code"
            :dense="true"
            :outlined="true"
            :hide-details="true"
            @zip-selected="zipSelected"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Country code"
            v-model="billThirdParty.countryCode"
            dense
            outlined
            hide-details
            @change="inp"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import CustomerAddressAc from "./CustomerAddressAc";
export default {
  name: "BillThirdParty",
  props: ["value"],
  components: { CustomerAddressAc },
  data() {
    return {
      useThirdParty: false,
      billThirdParty: null
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val === null) {
          this.useThirdParty = false;
          this.billThirdParty = null;
        } else {
          this.useThirdParty = true;
          this.billThirdParty = this.base();
          this.billThirdParty.accountNumber = val.accountNumber || "";
          this.billThirdParty.postalCode = val.postalCode || "";
          this.billThirdParty.countryCode = val.countryCode || "";
        }
      }
    }
  },
  methods: {
    zipSelected(data) {
      this.billThirdParty.countryCode = data.province.code.substr(0, 2);
      this.inp();
    },
    base() {
      return {
        accountNumber: "",
        postalCode: "",
        countryCode: ""
      };
    },
    inp() {
      if (this.useThirdParty) {
        if (!this.billThirdParty) {
          this.billThirdParty = this.base();
        }
      } else {
        this.billThirdParty = null;
      }
      this.$emit("input", this.billThirdParty);
    }
  }
};
</script>

<style scoped></style>
