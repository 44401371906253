var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mt-3"},[_c('v-text-field',{staticClass:"mr-5",attrs:{"label":'Width ('+_vm.size.dimUom+')',"type":"number","min":"1","max":"150","dense":"","hide-details":""},model:{value:(_vm.size.width),callback:function ($$v) {_vm.$set(_vm.size, "width", $$v)},expression:"size.width"}}),_c('v-text-field',{staticClass:"mr-5",attrs:{"label":'Length ('+_vm.size.dimUom+')',"type":"number","min":"1","max":"150","dense":"","hide-details":""},model:{value:(_vm.size.length),callback:function ($$v) {_vm.$set(_vm.size, "length", $$v)},expression:"size.length"}}),_c('v-text-field',{staticClass:"mr-5",attrs:{"label":'Height ('+_vm.size.dimUom+')',"type":"number","min":"1","max":"150","dense":"","hide-details":""},model:{value:(_vm.size.height),callback:function ($$v) {_vm.$set(_vm.size, "height", $$v)},expression:"size.height"}}),_c('v-text-field',{attrs:{"label":'Weight ('+_vm.size.weightUom+')',"type":"number","min":"1","max":"150","dense":"","hide-details":""},model:{value:(_vm.size.weight),callback:function ($$v) {_vm.$set(_vm.size, "weight", $$v)},expression:"size.weight"}})],1),_c('div',{staticClass:"d-flex mt-5"},[_c('v-select',{staticClass:"mr-5",attrs:{"label":"Size unit","items":[{text: 'Centimeters', value: 'CM'}, {text: 'Inches', value: 'IN'}],"dense":"","hide-details":""},model:{value:(_vm.size.dimUom),callback:function ($$v) {_vm.$set(_vm.size, "dimUom", $$v)},expression:"size.dimUom"}}),_c('v-select',{staticClass:"mr-5",attrs:{"label":"Weight unit","items":[{text: 'Pounds', value: 'LBS'}, {text: 'Kilograms', value: 'KGS'}],"dense":"","hide-details":""},model:{value:(_vm.size.weightUom),callback:function ($$v) {_vm.$set(_vm.size, "weightUom", $$v)},expression:"size.weightUom"}}),_c('v-select',{attrs:{"label":"Service","items":[
        {text: 'AIR_1DAYEARLYAM', value: '14'},
        {text: 'AIR_1DAY', value: '01'},
        {text: 'AIR_1DAYSAVER', value: '13'},
        {text: 'AIR_2DAYAM', value: '59'},
        {text: 'AIR_2DAY', value: '02'},
        {text: '3DAYSELECT', value: '12'},
        {text: 'GROUND', value: '03'},
        {text: 'SURE_POST', value: '93'}
        ],"dense":"","hide-details":""},model:{value:(_vm.size.service),callback:function ($$v) {_vm.$set(_vm.size, "service", $$v)},expression:"size.service"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }