<template>
  <div>
    <div class="d-flex mt-3">
      <v-text-field :label="'Width ('+size.dimUom+')'" type="number" min="1" max="150" v-model="size.width" dense hide-details class="mr-5" />
      <v-text-field :label="'Length ('+size.dimUom+')'" type="number" min="1" max="150" v-model="size.length" dense hide-details class="mr-5" />
      <v-text-field :label="'Height ('+size.dimUom+')'" type="number" min="1" max="150" v-model="size.height" dense hide-details class="mr-5" />
      <v-text-field :label="'Weight ('+size.weightUom+')'" type="number" min="1" max="150" v-model="size.weight" dense hide-details />
    </div>
    <div class="d-flex mt-5">
      <v-select label="Size unit" :items="[{text: 'Centimeters', value: 'CM'}, {text: 'Inches', value: 'IN'}]" class="mr-5" dense hide-details v-model="size.dimUom" />
      <v-select label="Weight unit" :items="[{text: 'Pounds', value: 'LBS'}, {text: 'Kilograms', value: 'KGS'}]" class="mr-5" dense hide-details v-model="size.weightUom" />
      <v-select label="Service" :items="[
          {text: 'AIR_1DAYEARLYAM', value: '14'},
          {text: 'AIR_1DAY', value: '01'},
          {text: 'AIR_1DAYSAVER', value: '13'},
          {text: 'AIR_2DAYAM', value: '59'},
          {text: 'AIR_2DAY', value: '02'},
          {text: '3DAYSELECT', value: '12'},
          {text: 'GROUND', value: '03'},
          {text: 'SURE_POST', value: '93'}
          ]" v-model="size.service" dense hide-details />
    </div>
  </div>
</template>

<script>
export default {
  name: "ShipmentSize",
  props: ['value'],
  data () {
    return {
      size: {
        width: 10,
        length: 10,
        height: 10,
        weight: 5,
        dimUom: 'IN',
        weightUom: 'LBS',
        service: '03'
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler () {
        this.mapValue()
      }
    }
  },
  methods: {
    mapValue() {
      if(this.value) {
        for(const k in this.size) {
          if (this.value[k]) {
            this.size[k] = this.value[k]
          }
        }
      } else {
        this.$emit('input', this.size)
      }
    }
  }
}
</script>

<style scoped>

</style>
