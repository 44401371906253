<template>
  <div>
    <div>
      {{ address.addressLine1 }}
    </div>
    <div>
      {{ address.addressLine2 }}
    </div>
    <div>
      {{ address.city }}<span v-if="provinceFromAddress(address)">, {{ provinceFromAddress(address).name }}</span>, {{ address.postalCode }}
    </div>
    <div v-if="countryFromAddress(address)">
      {{ countryFromAddress(address).name }}
    </div>
  </div>
</template>

<script>
import functions from "../mixins/functions";

export default {
  name: "AddressRender",
  props: ['address'],
  mixins: [functions]
}
</script>

<style scoped>

</style>
